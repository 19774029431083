import { GOOGLE_MAPS_KEY } from '@/constants/index.js'

let google

const setupGoogleScript = googleMapsUrl => {
	const googleScript = document.createElement('script')
	googleScript.setAttribute('src', googleMapsUrl)
	googleScript.setAttribute('defer', '')
	googleScript.setAttribute('async', '')
	googleScript.id = 'googleMapsScript'
	return googleScript
}

const load = () => {
	return new Promise((resolve, reject) => {
		if (typeof document === 'undefined') {
			reject(new Error('document not loaded '))
		}
		if (typeof google !== 'object' && !document.getElementById('googleMapsScript')) {
			const googleMapsUrl = 'https://maps.googleapis.com/maps/api/js?v=quarterly&key=' + GOOGLE_MAPS_KEY

			const googleScript = setupGoogleScript(googleMapsUrl)
			document.head.appendChild(googleScript)

			googleScript.onload = function () {
				resolve(google)
			}
		} else {
			resolve(google)
		}
	}).catch(function (e) {
		return e
	})
}

export default {
	load
}
