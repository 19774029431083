<template>
	<div
		class="absolute bottom-0 flex justify-center w-full p-3 pointer-events-none"
	>
		<div class="relative max-w-md pointer-events-auto">
			<div class="absolute top-0 right-0">
				<button
					class="relative w-6 h-6 m-2 leading-4 border border-gray-300 rounded-full"
					@click="$emit('hide-business-info')"
				>
					<span class="absolute inset-0 top-px">
						&#215;
					</span>
				</button>
			</div>
			<BusinessMapCard
				class="border border-gray-300 rounded-lg shadow-md"
				:business="selectedBusiness"
			/>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import BusinessMapCard from '@/components/business/BusinessMapCard.vue'

export default {
	components: {
		BusinessMapCard
	},
	emits: [ 'hide-business-info' ],
	computed: {
		...mapGetters('map', [ 'selectedBusiness' ]),
		...mapGetters([ 'isMobile' ])
	}
}
</script>
