<template>
	<div class="fixed w-full p-4 inset-x-0 bottom-0">
		<div
			class="bg-white shadow-md border rounded-xl border-solid border-gray-300"
		>
			<search-empty-state>
				<template #title>
					No results
				</template>
				<template #message>
					<div class="m-auto w-4/5">
						No results found, try zooming out or changing your search filters.
					</div>
				</template>
			</search-empty-state>
		</div>
	</div>
</template>

<script async>
import SearchEmptyState from '@/components/search/SearchEmptyState.vue'

export default {
	components: { SearchEmptyState }
}
</script>
