<template>
	<div class="relative flex-1">
		<div
			id="gmap"
			ref="gmap"
			class="w-full h-full"
		/>

		<SelectedBusinessInfoCard
			v-if="selectedBusiness"
			v-show="searchMode === MAP"
			@hide-business-info="clearSelectedBusiness"
		/>

		<SearchAreaButton
			v-if="showSearchAreaButton"
			@search="search"
		/>

		<EmptySearchResultsCard
			v-if="showEmptyState"
			v-show="searchMode === MAP"
		/>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import { fetchBusiness } from '@/api/map/index.js'
import EmptySearchResultsCard from '@/components/map/EmptySearchResultsCard.vue'
import SearchAreaButton from '@/components/map/SearchAreaButton.vue'
import SelectedBusinessInfoCard from '@/components/map/SelectedBusinessInfoCard.vue'
import { CLAIMED, FEATURED, PREMIUM, UNCLAIMED, VERIFIED } from '@/constants/map/icon-z-indexes.js'
import { MAP } from '@/constants/map/map-modes.js'
import mapStyles from '@/constants/map/map-styles.json'
import { ERROR } from '@/constants/toast/type.js'
import markerIcons from '@/etc/generated/listingIconMap.json'
import googleMaps from '@/plugins/googleMaps.js'
import { logError } from '@/utils/error-handling.js'
import { deleteResizedMarker, resizeSelectedMarker } from '@/utils/map.js'

export default {
	name: 'BusinessMap',
	components: {
		EmptySearchResultsCard,
		SelectedBusinessInfoCard,
		SearchAreaButton
	},
	props: {
		isLoading: {
			type: Boolean,
			required: true
		},
		businesses: {
			type: Object,
			default() {
				return {}
			}
		},
		activeFilters: {
			type: Array,
			default: () => ([])
		}
	},
	emits: [ 'fetch-businesses' ],
	data() {
		return {
			map: false,
			markers: {},
			markerIcons,
			mapLoadAttempts: 0,
			mapBounds: false,
			shouldShowBusinessInfo: false,
			showSearchAreaButton: false,
			MAP
		}
	},
	computed: {
		...mapGetters('map', [ 'selectedBusiness', 'searchMode' ]),
		...mapGetters([ 'isMobile', 'cityCoordinates' ]),
		hasBusinesses() {
			return !!this.businesses && !!Object.keys(this.businesses).length
		},
		showEmptyState() {
			return !this.hasBusinesses && !this.isLoading && this.isMobile
		}
	},
	watch: {
		businesses() {
			this.addMarkers()
		},
		activeFilters() {
			this.search()
		},
		selectedBusiness(business) {
			if (!business) {
				deleteResizedMarker()
			}
		},
		cityCoordinates: {
			handler() {
				this.initalizeMap()
			},
			deep: true,
			immediate: true
		}
	},
	beforeDestroy() {
		if (!this.map || !window?.google?.maps?.event) return
		try {
			window.google.maps.event.clearListeners(this.map, 'bounds_changed')
			window.google.maps.event.clearListeners(this.map, 'idle')
			window.google.maps.event.clearListeners(this.map, 'click')
		} catch (error) {
			logError(error)
		}
	},
	methods: {
		...mapMutations('map', [ 'setSelectedBusiness' ]),
		...mapMutations('toast', [ 'showToast' ]),
		async initalizeMap() {
			if (!window?.google?.maps) {
				await googleMaps.load().then(() => {
					this.createMap()
				})
			} else {
				this.createMap()
			}
		},
		async fetchBusinesses() {
			this.$emit('fetch-businesses', this.mapBounds)
		},
		async addMapEventListeners() {
			window.google.maps.event.addListener(this.map, 'bounds_changed', () => {
				this.setMapBounds()
				this.showSearchAreaButton = true
			})

			window.google.maps.event.addListenerOnce(this.map, 'idle', () => {
				this.setMapBounds(this.search)
				this.showSearchAreaButton = false
			})
		},
		async createMap() {
			try {
				this.mapLoadAttempts++
				const mapElement = this.$refs.gmap
				if (mapElement) {
					this.map = await new window.google.maps.Map(mapElement, {
						center: { lat: parseFloat(this.cityCoordinates?.lat), lng: parseFloat(this.cityCoordinates?.lon) },
						zoom: 12,
						maxZoom: 17,
						minZoom: 6,
						styles: mapStyles,
						disableDefaultUI: true,
						zoomControl: false
					})
					this.addMapEventListeners()
				}
			} catch (e) {
				logError(e)
				if (this.mapLoadAttempts <= 5) {
					setTimeout(() => this.initalizeMap(), 300)
				}
			}
		},
		geoLocate() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					this.map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude })
				})
			} else {
				this.showToast({
					primaryText: 'Error',
					secondaryText: 'Your browser does not support geolocation',
					type: ERROR
				})
			}
		},
		isPremiumBusiness(business) {
			return business.featured && business.featured > 1
		},
		isFeaturedBusiness(business) {
			return business.featured && business.featured <= 1
		},
		isUnclaimedBusiness(business) {
			return !business.featured &&
				!business.verified &&
				!business.claimed
		},
		getZIndex(business) {
			let zIndex

			if (this.isPremiumBusiness(business)) zIndex = PREMIUM
			else if (this.isFeaturedBusiness(business)) zIndex = FEATURED
			else if (business.verified) zIndex = VERIFIED
			else if (business.claimed) zIndex = CLAIMED
			else zIndex = UNCLAIMED
			return zIndex
		},
		getIconByBusinessPackage(business) {
			let businessPackage

			if (this.isPremiumBusiness(business)) businessPackage = 'premium'
			else if (this.isFeaturedBusiness(business)) businessPackage = 'featured'
			else if (business.verified) businessPackage = 'verified'
			else if (business.claimed) businessPackage = 'claimed'
			else businessPackage = 'unclaimed'

			let icon
			try {
				icon = require(`@/assets/icons/map-pins/${businessPackage}-${business.type}.svg`)
			} catch (error) {
				icon = require(`@/assets/icons/map-pins/${businessPackage}-dispensary.svg`)
			}
			return icon
		},
		configureMarker(business) {
			return {
				icon: this.getIconByBusinessPackage(business),
				zIndex: this.getZIndex(business)
			}
		},
		addMarker(business) {
			const {
				icon, zIndex
			} = this.configureMarker(business)

			const position = {
				lat: parseFloat(business.lat),
				lng: parseFloat(business.lon)
			}

			const marker = new window.google.maps.Marker({
				position,
				map: this.map,
				icon,
				customInfo: business,
				businessId: business.id,
				zIndex,
				shadow: 'none'
			})

			return marker
		},
		async handleMarkerClick(marker) {
			try {
				const businessId = marker.customInfo.id

				const { results } = await fetchBusiness(businessId)
				if (!results) return
				const clickedBusiness = results.business
				await this.$nextTick()
				resizeSelectedMarker({
					map: this.map, marker, percentageToResize: 20
				})
				this.setSelectedBusiness(clickedBusiness)
			} catch (e) {
				logError(e)
			}
		},
		addMarkers() {
			for (const i in this.businesses) {
				if (this.markers[i] > -1) { continue }

				const marker = this.addMarker(this.businesses[i])
				window.google.maps.event.addListener(
					marker,
					'click',
					() => this.handleMarkerClick(marker)
				)
				this.markers[i] = marker
			}
		},
		setMapBounds(setMapBoundsCallback) {
			try {
				const mapBounds = this.map.getBounds()
				const mapSouthwest = mapBounds.getSouthWest()
				const mapNortheast = mapBounds.getNorthEast()
				this.mapBounds = `${mapSouthwest.lat()}|${mapSouthwest.lng()}|${mapNortheast.lat()}|${mapNortheast.lng()}`
				if (setMapBoundsCallback) {
					setMapBoundsCallback()
				}
			} catch (e) {
				logError(e)
				return false
			}
		},
		async search() {
			this.deleteMarkers()
			this.showSearchAreaButton = false
			this.clearSelectedBusiness()
			this.fetchBusinesses()
		},
		clearSelectedBusiness() {
			this.setSelectedBusiness(null)
		},
		deleteMarkers() {
			for (const markerKey in this.markers) {
				this.markers[markerKey].setMap(null)
			}
			this.markerIds = []
		},
		getMapIconString(businessType) {
			return businessType[0].toUpperCase() + businessType.substring(1)
		}
	}
}
</script>
